
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";

import Utils from "@/utility/utils";

export default defineComponent({
  components: {
    Calendar,
    Column,
    DataTable,
    Dialog,
  },
  emits: ["hide"],
  created() {
    this.hidden = this.show;
    this.dateRange = [
      new Date(this.periodStartDate),
      new Date(this.periodEndDate),
    ];
  },
  computed: {},
  methods: {
    ...mapActions({
      getRegister: "generalLedger/getRegister",
    }),
    formatedAmount(amt: string) {
      if (!amt || parseInt(amt) === 0) {
        return "";
      } else {
        return Utils.formatPrice(amt);
      }
    },
    getRegisterData() {
      this.loading = true;
      this.getRegister({
        registerFile: this.file,
        startDate: this.dateRange[0].toLocaleDateString("en-US"),
        endDate: this.dateRange[1].toLocaleDateString("en-US"),
      })
        .then((response) => {
          var data = [];
          var idName = "";
          switch (this.file) {
            case "ARREG":
              data = response.arreg_items;
              idName = "arreg_id";
              break;
            case "APREG":
              data = response.apreg_items;
              idName = "apreg_id";
              break;
            case "WOREG":
              data = response.woreg_items;
              idName = "woreg_id";
              break;
            case "INVREG":
              data = response.invreg_items;
              idName = "invreg_id";
              break;
          }
          this.tableData = data.map((item: any, index: number) => {
            return {
              ...item,
              id: item[idName],
            };
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hideDialog() {
      this.hidden = true;
      this.tableData = [];
      this.dateRange = [
        new Date(this.periodStartDate),
        new Date(this.periodEndDate),
      ];
      this.$emit("hide", this.hidden);
    },
    handleHideCalendar(event: any) {
      if (!event[0] || event[1] === null) return;
      this.getRegisterData();
    },
  },
  data() {
    return {
      hidden: true,
      tableData: [],
      loading: false,
      dateRange: null as any,
    };
  },
  props: {
    title: String,
    show: Boolean,
    file: String,
    periodStartDate: {
      type: String,
      required: true,
    },
    periodEndDate: {
      type: String,
      required: true,
    },
  },
  watch: {
    show(newShow) {
      if (newShow) {
        this.getRegisterData();
      }
      this.hidden = newShow;
    },
    periodStartDate(newStartDate) {
      this.dateRange[0] = new Date(newStartDate);
    },
    periodEndDate(newEndDate) {
      this.dateRange[1] = new Date(newEndDate);
    },
  },
});
