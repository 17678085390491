
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";

export default defineComponent({
  components: {
    Button,
    Calendar,
    Dialog,
    Dropdown,
    InputText,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["hide", "save"],
  data() {
    return {
      hidden: this.show,
      startDate: undefined as Date | undefined,
      endDate: undefined as Date | undefined,
      journalTypes: "",
      glTransList: [{ value: "" }] as { value: string }[],
      journalTypesOptions: ["Register", "GLTrans", "All"],
    };
  },
  methods: {
    ...mapActions({
      glPost: "generalLedger/glPost",
    }),
    addGLTransaction(index: number) {
      this.glTransList.splice(index + 1, 0, { value: "" });
    },
    deleteGLTransaction(index: number) {
      this.glTransList.splice(index, 1);
      if (this.glTransList.length == 0) {
        this.glTransList.push({ value: "" });
      }
    },
    postGL() {
      this.glPost({
        startDate: this.startDate?.toLocaleDateString("en-US"),
        endDate: this.endDate?.toLocaleDateString("en-US"),
        journalTypes: this.journalTypes,
        glTransList: this.glTransList
          .map((item) => {
            return item.value;
          })
          .join(","),
      }).then(() => {
        this.$emit("save");
        this.hideDialog();
      });
    },
    hideDialog() {
      this.hidden = false;
      this.$emit("hide");
      this.startDate = undefined;
      this.endDate = undefined;
      this.journalTypes = "";
      this.glTransList = [{ value: "" }];
    },
  },
  watch: {
    show: function (newVal, oldVal) {
      this.hidden = newVal;
    },
  },
});
