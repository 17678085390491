import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap align-items-center justify-content-between p-0 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: "GL Transactions Listing",
    visible: this.hidden,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((this.hidden) = $event)),
    onHide: _ctx.hideDialog,
    style: { width: '1000px' },
    modal: true,
    draggable: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        value: _ctx.tableData,
        expandedRows: _ctx.expandedRows,
        "onUpdate:expandedRows": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expandedRows) = $event)),
        breakpoint: "1267px",
        dataKey: "trans",
        paginator: true,
        rowsPerPageOptions: [10, 25, 50],
        rows: 10,
        loading: _ctx.loading
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_Calendar, {
              modelValue: _ctx.dateRange,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRange) = $event)),
                _ctx.handleHideCalendar
              ],
              format: "MM/dd/yyyy",
              selectionMode: "range",
              manualInput: false,
              placeholder: "Select Date Range",
              showButtonBar: true,
              showIcon: true,
              class: "h-2rem"
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ])
        ]),
        empty: _withCtx(() => [
          _createTextVNode("No record found")
        ]),
        expansion: _withCtx((slotProps) => [
          _createElementVNode("div", null, [
            _createVNode(_component_DataTable, {
              value: slotProps.data.lines_items
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "accts",
                  header: "Account"
                }),
                _createVNode(_component_Column, {
                  field: "line_notes",
                  header: "Line Notes",
                  headerClass: "font-bold",
                  bodyClass: "font-normal font-semibold text-right"
                }),
                _createVNode(_component_Column, {
                  field: "amounts",
                  header: "Debit Amount",
                  headerClass: "font-bold",
                  bodyClass: "font-normal font-semibold text-right"
                }, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.getDebitAmount(+data.amounts)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "amounts",
                  header: "Credit Amount",
                  headerClass: "font-bold",
                  bodyClass: "font-normal font-semibold text-right"
                }, {
                  body: _withCtx(({ data }) => [
                    _createTextVNode(_toDisplayString(_ctx.getCreditAmount(+data.amounts)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ColumnGroup, { type: "footer" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Row, { class: "totals" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          footer: 'Total Balance: ',
                          colspan: 2,
                          class: "text-right font-bold",
                          footerStyle: "text-align:right"
                        }),
                        _createVNode(_component_Column, {
                          footer: 
                    _ctx.formatedAmount(
                      _ctx.getTotalDebitsBalance(slotProps.data.lines_items),
                    )
                  ,
                          class: "text-right font-bold",
                          footerStyle: "text-align:right"
                        }, null, 8, ["footer"]),
                        _createVNode(_component_Column, {
                          footer: 
                    _ctx.formatedAmount(
                      _ctx.getTotalCreditsBalance(slotProps.data.lines_items),
                    )
                  ,
                          class: "text-right font-bold",
                          footerStyle: "text-align:right"
                        }, null, 8, ["footer"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["value"])
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, { expander: "" }),
          _createVNode(_component_Column, {
            field: "trans",
            header: "Id",
            headerClass: "font-bold",
            bodyClass: "font-normal text-center",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "date",
            header: "Reg Date",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-center",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "post_date",
            header: "Post Date",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-right",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "journal",
            header: "Journal",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-right",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "desc",
            header: "Description",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-right",
            sortable: ""
          })
        ]),
        _: 1
      }, 8, ["value", "expandedRows", "loading"])
    ]),
    _: 1
  }, 8, ["visible", "onHide"]))
}