import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap align-items-center justify-content-between p-0 m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: this.title,
    visible: this.hidden,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((this.hidden) = $event)),
    onHide: _ctx.hideDialog,
    style: { width: '1000px' },
    modal: true,
    draggable: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        value: _ctx.tableData,
        breakpoint: "1267px",
        "data-key": "id",
        paginator: true,
        rowsPerPageOptions: [10, 25, 50],
        rows: 10,
        loading: _ctx.loading
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_Calendar, {
              modelValue: _ctx.dateRange,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRange) = $event)),
                _ctx.handleHideCalendar
              ],
              format: "MM/dd/yyyy",
              selectionMode: "range",
              manualInput: false,
              placeholder: "Select Date Range",
              showButtonBar: true,
              showIcon: true,
              class: "h-2rem"
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ])
        ]),
        empty: _withCtx(() => [
          _createTextVNode("No record found")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "id",
            header: "Id",
            headerClass: "font-bold",
            bodyClass: "font-normal text-center",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "date",
            header: "Reg Date",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-center",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "gl_date",
            header: "Post Date",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-center",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "account",
            header: "Account",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-right",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "accountDescription",
            header: "Description",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-right",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "procedure",
            header: "Procedure",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-right",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "record",
            header: "Record Id",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-right",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "name",
            header: "Name",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-right",
            sortable: ""
          }),
          _createVNode(_component_Column, {
            field: "amount",
            header: "Amount",
            headerClass: "font-bold",
            bodyClass: "font-normal font-semibold text-right",
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.formatedAmount(data.amount)), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "loading"])
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}