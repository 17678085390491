import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-451e01e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "statcard-wrapper" }
const _hoisted_2 = {
  class: "statcard-content grid p-0",
  style: {"height":"auto"}
}
const _hoisted_3 = {
  class: "col-12 md:col-6 left-section",
  style: {"width":"100%"}
}
const _hoisted_4 = {
  class: "col-12 md:col-6 gid right-section",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Receivables = _resolveComponent("Receivables")!
  const _component_VerticalStat = _resolveComponent("VerticalStat")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, {
      class: _normalizeClass({ 'statcard-selected': _ctx.selected })
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Receivables, {
              title: _ctx.title,
              items: _ctx.statInfo.horizontalStatsInfo,
              showBars: true,
              showTotal: false,
              roundTotals: true,
              showInCard: false,
              titleAlign: "left",
              "onRow:click": _ctx.handleRowClick
            }, null, 8, ["title", "items", "onRow:click"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_VerticalStat, {
              statInfo: _ctx.statInfo.verticalStatsInfo
            }, null, 8, ["statInfo"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}