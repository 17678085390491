
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";

export default defineComponent({
  components: {
    Button,
    Calendar,
    Checkbox,
    Dialog,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    file: {
      type: String,
      required: true,
    },
  },
  emits: ["hide", "save"],
  data() {
    return {
      hidden: this.show,
      startDate: undefined as Date | undefined,
      endDate: undefined as Date | undefined,
      postByDate: "",
      transactionDetail: "",
      includeClosedPeriods: "",
    };
  },
  methods: {
    ...mapActions({
      postInterfaceRegister: "generalLedger/postInterfaceRegister",
    }),
    interfaceRegister() {
      this.postInterfaceRegister({
        modules: this.file,
        startDate: this.startDate?.toLocaleDateString("en-US"),
        endDate: this.endDate?.toLocaleDateString("en-US"),
        postByDate: this.postByDate,
        postDetail: this.transactionDetail,
        includeClosed: this.includeClosedPeriods,
      }).then(() => {
        this.$emit("save");
        this.hideDialog();
      });
    },
    hideDialog() {
      this.hidden = false;
      this.$emit("hide");
      this.startDate = undefined;
      this.endDate = undefined;
      this.postByDate = "";
      this.transactionDetail = "";
      this.includeClosedPeriods = "";
    },
  },
  watch: {
    show: function (newVal, oldVal) {
      this.hidden = newVal;
    },
  },
});
