
import { defineComponent } from "vue";

import Card from "primevue/card";
import Button from "primevue/button";
import { mapActions } from "vuex";
import RegisterDialog from "@/components/Accounting/GL/RegisterDialog.vue";
import GLInterfaceDialog from "@/components/Accounting/GL/GLInterfaceDialog.vue";
import GLPostDialog from "@/components/Accounting/GL/GLPostDialog.vue";
import GLReportDialog from "@/components/Accounting/GL/GLReportDialog.vue";
import LoadingSpinner from "../LoadingSpinner.vue";

export default defineComponent({
  components: {
    Card,
    Button,
    GLInterfaceDialog,
    GLPostDialog,
    GLReportDialog,
    LoadingSpinner,
    RegisterDialog,
  },
  created() {
    this.updateRegisterData();
  },
  computed: {
    postingCategories(): string[] {
      return Object.keys(this.postingProgress);
    },
  },
  methods: {
    ...mapActions({
      fetchMonthEndProgressKpi: "generalLedger/fetchMonthEndProgressKpi",
      postInterfaceRegister: "generalLedger/postInterfaceRegister",
    }),
    updateRegisterData() {
      this.isLoading = true;
      this.fetchMonthEndProgressKpi()
        .then((response) => {
          this.currentFiscalYear = response.fiscalYear;
          this.currentOpenPeriod = response.fiscalPeriod;
          this.postingProgress = response.postingMetrics;
          this.periodStartDate = response.periodStarts;
          this.periodEndDate = response.periodEnds;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showInterfaceRegister(registerType: string) {
      this.showInterfaceRegisterDialog = true;
      this.showRegisterFile = registerType.replace("REG", "");
    },
    hideInterfaceRegister() {
      this.showInterfaceRegisterDialog = false;
    },
    hideGLPostDialog() {
      this.showGLPostDialog = false;
    },
    hideGLReportDialog() {
      this.showGLReportdialog = false;
    },
    showRegisterReport(registerType: string) {
      this.showRegisterDialog = true;
      this.showRegisterFile = registerType;
      this.registerDialogTitle = `Register report for ${registerType}`;
    },
  },
  data() {
    return {
      currentFiscalYear: "",
      currentOpenPeriod: "",
      periodStartDate: "",
      periodEndDate: "",
      monthEndClosingProgress: 10,
      postingProgress: {} as {
        [key: string]: { total: string; unposted: string };
      },
      showRegisterDialog: false,
      showRegisterFile: "",
      registerDialogTitle: "",
      showInterfaceRegisterDialog: false,
      showGLPostDialog: false,
      isLoading: false,
      showGLReportdialog: false,
      filteredStatusData: {
        test: ["test"],
        testStepTwo: ["test step two"],
      } as { [key: string]: string[] },
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    roundTotals: {
      type: Boolean,
      default: true,
    },
  },
});
