import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full grid m-0 p-0 px-1 top-statcards-wrapper" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatCard = _resolveComponent("StatCard")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_StatCardGl = _resolveComponent("StatCardGl")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.filter((item) => item.label !== 'GL'), (card, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: card.label,
        class: _normalizeClass(["xl:col-4 xl:block hidden col-12 m-0 p-1 pl-2 pr-1", { block: _ctx.$route.fullPath?.toString().includes(card.to) }]),
        onClick: ($event: any) => (_ctx.handleTabChange(index))
      }, [
        _createVNode(_component_router_link, {
          to: card.to,
          class: "no-underline"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_StatCard, {
              title: card.label + 's',
              selected: _ctx.$route.fullPath?.toString().includes(card.to) ?? false,
              statInfo: card.statInfo,
              "onRow:click": _ctx.handleRowClick
            }, null, 8, ["title", "selected", "statInfo", "onRow:click"])
          ]),
          _: 2
        }, 1032, ["to"])
      ], 10, _hoisted_2))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.filter((item) => item.label === 'GL'), (card) => {
      return (_openBlock(), _createElementBlock("div", {
        key: card.label,
        class: _normalizeClass(["xl:col-4 xl:block hidden col-12 pt-3 pl-3", {'block': _ctx.$route.fullPath?.toString().includes(card.to)}]),
        onClick: ($event: any) => (_ctx.handleTabChange(_ctx.items.findIndex((item) => item.label === card.label) ))
      }, [
        _createVNode(_component_router_link, {
          to: card.to,
          class: "no-underline grid"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_StatCardGl, {
              title: card.label,
              selected: _ctx.$route.fullPath?.toString().includes(card.to)  ?? false
            }, null, 8, ["title", "selected"])
          ]),
          _: 2
        }, 1032, ["to"])
      ], 10, _hoisted_3))
    }), 128))
  ]))
}